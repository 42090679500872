//
// #### ##    ## #### ########
//  ##  ###   ##  ##     ##
//  ##  ####  ##  ##     ##
//  ##  ## ## ##  ##     ##
//  ##  ##  ####  ##     ##
//  ##  ##   ###  ##     ##
// #### ##    ## ####    ##


// ##     ##    ###    ########   ######
// ##     ##   ## ##   ##     ## ##    ##
// ##     ##  ##   ##  ##     ## ##
// ##     ## ##     ## ########   ######
//  ##   ##  ######### ##   ##         ##
//   ## ##   ##     ## ##    ##  ##    ##
//    ###    ##     ## ##     ##  ######

// IDecko alebo classa cielovej cast kam sa ma disclaimer pridat
const targetContainer = "#site_wraper";
// class samotneho containera
const containerClass = 'cookie-consent';
// text, ktory bude napisany
const cookieDisclaimer = 'Používaním stránok prevádzkovaných PAPA GRILL s. r. o. súhlasíte s používaním cookies, <br />ktoré nám pomáhajú zabezpečiť lepšie služby.';
//text suhlasneho tlacitka
const acceptText = 'Rozumiem';
const acceptBtnClass = 'accept_cookies'

// Tlacitko viac info
// text tlacitka "viac info"
const moreInfoText = 'Viac informácií';
// url kam ma tlacitko smerovat
const moreInfo_url = 'cookies';
const moreInfo_url_how = '_blank';

let innerContainer =  '<div class="container">'
    innerContainer += '<div class="row">';
    innerContainer += '<div class="col-lg-8 '+containerClass+'__disclaimer"></div>';
    innerContainer += '<div class="col text-right ml-auto '+containerClass+'__buttons"></div>';
    innerContainer += '</div>'
    innerContainer += '</div>'

// ====================================================================================
// ====================================================================================
// ====================================================================================

// Create cookie consent container

const cookieContainer = document.createElement('section');
      cookieContainer.classList.add(containerClass);
      cookieContainer.innerHTML = innerContainer;

const buttonConsent = document.createElement('button');
      buttonConsent.innerHTML = acceptText;
      buttonConsent.classList.add('btn', 'btn-primary', acceptBtnClass);

const buttonMoreInfo = document.createElement('a');
      buttonMoreInfo.innerHTML = moreInfoText;
      buttonMoreInfo.classList.add('btn', 'btn-link');
      buttonMoreInfo.setAttribute('href', moreInfo_url);
      buttonMoreInfo.setAttribute('target', moreInfo_url_how);

const disclaimerContainer = cookieContainer.querySelector('.cookie-consent__disclaimer');
      disclaimerContainer.innerHTML = '<p>'+cookieDisclaimer+'</p>';

const buttonContainer = cookieContainer.querySelector('.cookie-consent__buttons');
      buttonContainer.appendChild(buttonConsent);
      //buttonContainer.appendChild(buttonMoreInfo);

const accept_button = buttonContainer.querySelector('.'+acceptBtnClass);
      accept_button.addEventListener('click', function functionName(e) {
          setCookie('cookie_consent', true, 10);
          document.querySelector('.'+containerClass).classList.add('hide');
          console.log('zapisal som suhlas');
      });



const cookiePresent = getCookie('cookie_consent');

// console.log(cookiePresent);
// POSLEDNY KROK PRIDAME CONSENT DO DOKUMENTU
const target = document.querySelector(targetContainer);
//console.log(target);
if(!cookiePresent){
  target.appendChild(cookieContainer);
}


// ######## ##     ## ##    ##  ######  ######## ####  #######  ##    ##  ######
// ##       ##     ## ###   ## ##    ##    ##     ##  ##     ## ###   ## ##    ##
// ##       ##     ## ####  ## ##          ##     ##  ##     ## ####  ## ##
// ######   ##     ## ## ## ## ##          ##     ##  ##     ## ## ## ##  ######
// ##       ##     ## ##  #### ##          ##     ##  ##     ## ##  ####       ##
// ##       ##     ## ##   ### ##    ##    ##     ##  ##     ## ##   ### ##    ##
// ##        #######  ##    ##  ######     ##    ####  #######  ##    ##  ######


//set the cookie
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
//get the cookie
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
