import "./cart_payment";
import "./cookie_consent";
import "./counter";
import "./form-validation";
import "./mobile-menu";
import "./numbered-index";
import "./offcanvas";
//import "./product-variant-selector";
import "./reveal-password";
//import "./scroll-animated";
//import "./scroller";
import "./shipping_adressbook";
import "./notifications-center";


//Vrat sa na zascrollovanu pozicu po rereshi
// document.addEventListener("DOMContentLoaded", function(event) { 
// 	var scrollpos = localStorage.getItem('scrollpos');
// 	if (scrollpos) window.scrollTo(0, scrollpos);
// });

// window.onbeforeunload = function(e) {
// 	localStorage.setItem('scrollpos', window.scrollY);
// };



$(document).ready(function() {

	$('.search-select').selectpicker();

	// Resize tela stranky kvoli position fixed hlavneho menu
	const changeBodyPadding = () => {
		let mainNavHeight = $('.main-nav').outerHeight();
		$('#page-wraper').css({'padding-top' : mainNavHeight});
		$('.notifications-center').css({'top' : mainNavHeight});

		//console.log('resize');
	};
	changeBodyPadding();
	window.addEventListener('resize', changeBodyPadding);

	gsap.fromTo('.notifications-center .user-message', {xPercent:100, opacity: 0}, {xPercent: 0, opacity: 1, stagger: .3, delay: 1, onComplete:()=>{
		setTimeout(()=>{
			gsap.to('.notifications-center .user-message', {x:30, opacity: 0, stagger: .3});
		}, 5000)
	}});

	// Schovanie notifikacii v notifikacnom centre
	
	


	
	




	//  ######  ##       #### ########  ########  ######  ##     ##  #######  ##      ## 
	// ##    ## ##        ##  ##     ## ##       ##    ## ##     ## ##     ## ##  ##  ## 
	// ##       ##        ##  ##     ## ##       ##       ##     ## ##     ## ##  ##  ## 
	//  ######  ##        ##  ##     ## ######    ######  ######### ##     ## ##  ##  ## 
	//       ## ##        ##  ##     ## ##             ## ##     ## ##     ## ##  ##  ## 
	// ##    ## ##        ##  ##     ## ##       ##    ## ##     ## ##     ## ##  ##  ## 
	//  ######  ######## #### ########  ########  ######  ##     ##  #######   ###  ###  


		$('.product-slideshow_container').each(function () {

			const slideShow = $(this).find('.product-slideshow');

			slideShow.slick({
				fade: false,
				dots: true,
				speed: 950,
				cssEase: "ease-in-out",
				lazyLoad: 'progressive',
				arrows: true,
				infinite: true,
				dotsClass: 'paging',
				draggable: true,
				cssEase: 'ease-in-out',
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				//variableWidth: true,
				//pauseOnFocus: true,
				prevArrow: $(this).find('.prev'),
				nextArrow: $(this).find('.next'),
				slidesToShow: 4,
				slidesToScroll: 4,
				responsive: [{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
							infinite: true,
							dots: true
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 526,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				],
			})
		});


	/*
	===========================
	########  #######     ########  #######  ########
	   ##    ##     ##       ##    ##     ## ##     ##
	   ##    ##     ##       ##    ##     ## ##     ##
	   ##    ##     ##       ##    ##     ## ########
	   ##    ##     ##       ##    ##     ## ##
	   ##    ##     ##       ##    ##     ## ##
	   ##     #######        ##     #######  ##
	===========================
	*/

	


	$('#to_top').on("click tap", function(){
		//or to scroll to the element with the ID "#someID":
		TweenMax.to($(window), 1, {scrollTo:"#site_root"});
	})


	// ########     ###    ######## #### ##    ##  ######
	// ##     ##   ## ##      ##     ##  ###   ## ##    ##
	// ##     ##  ##   ##     ##     ##  ####  ## ##
	// ########  ##     ##    ##     ##  ## ## ## ##   ####
	// ##   ##   #########    ##     ##  ##  #### ##    ##
	// ##    ##  ##     ##    ##     ##  ##   ### ##    ##
	// ##     ## ##     ##    ##    #### ##    ##  ######

	$('.rating').each( function(){
		var activeStars = $(this).attr("data-stars");
		var totalStars = $(this).children(".star").length;

		var inactiveClass = "fa-star-o";
		var activeClass = "fa-star";


		for (i = 0; i < activeStars; i++) {
			$(this).children(".star").eq(i).removeClass(inactiveClass).addClass(activeClass);
		}
	})



		/*
		===========================

		########   #######   #######  ########  ######  ######## ########     ###    ########
		##     ## ##     ## ##     ##    ##    ##    ##    ##    ##     ##   ## ##   ##     ##
		##     ## ##     ## ##     ##    ##    ##          ##    ##     ##  ##   ##  ##     ##
		########  ##     ## ##     ##    ##     ######     ##    ########  ##     ## ########
		##     ## ##     ## ##     ##    ##          ##    ##    ##   ##   ######### ##
		##     ## ##     ## ##     ##    ##    ##    ##    ##    ##    ##  ##     ## ##
		########   #######   #######     ##     ######     ##    ##     ## ##     ## ##

		===========================
		*/

		// AKTIVOVANIE TOOLTIPOV
		$('[data-toggle="tooltip"]').tooltip();

		// function toggleDropdown(e) {
		// 	const _d = $(e.target).closest('.dropdown'),
		// 		_m = $('.dropdown-menu', _d);
		// 	setTimeout(function () {
		// 		const shouldOpen = e.type !== 'click' && _d.is(':hover');
		// 		_m.toggleClass('show', shouldOpen);
		// 		_d.toggleClass('show', shouldOpen);
		// 		$('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
		// 	}, e.type === 'mouseleave' ? 300 : 0);
		// }

		// $('body')
		// 	.on('mouseenter mouseleave', '.dropdown-toggle', toggleDropdown)
		// 	.on('click', '.dropdown-menu a', toggleDropdown);


		/*
		 ######  ##     ##  ######
		##    ## ##     ## ##    ##
		##       ##     ## ##
		 ######  ##     ## ##   ####
		      ##  ##   ##  ##    ##
		##    ##   ## ##   ##    ##
		 ######     ###     ######
		*/
			$('img.svg').each(function(){
			    var $img = jQuery(this);
			    var imgID = $img.attr('id');
			    var imgClass = $img.attr('class');
			    var imgURL = $img.attr('src');



			    jQuery.get(imgURL, function(data) {
			        // Get the SVG tag, ignore the rest
			        var $svg = jQuery(data).find('svg');

			        // Add replaced image's ID to the new SVG
			        if(typeof imgID !== 'undefined') {
			            $svg = $svg.attr('id', imgID);
			        }
			        // Add replaced image's classes to the new SVG
			        if(typeof imgClass !== 'undefined') {
			            $svg = $svg.attr('class', imgClass+' replaced-svg');
			        }

			        // Remove any invalid XML tags as per http://validator.w3.org
			        $svg = $svg.removeAttr('xmlns:a');

			        // Replace image with new SVG
			        $img.replaceWith($svg);

			    }, 'xml');
			});

			//
			// #### ##     ##  ######
			//  ##  ###   ### ##    ##
			//  ##  #### #### ##
			//  ##  ## ### ## ##   ####
			//  ##  ##     ## ##    ##
			//  ##  ##     ## ##    ##
			// #### ##     ##  ######

			// Prehodit vsetky obrazky ako bcg img
			const allContentImages = document.querySelectorAll(".content-image");

			allContentImages.forEach(function(contentImage){
				const originalImage = contentImage.querySelector("img");
				const originalImageURL = originalImage.getAttribute("src");

				//console.log(originalImageURL);

				contentImage.style.backgroundImage = "url('"+originalImageURL+"')";
				//contentImage.style.background = ""
				//console.log(contentImage);
				originalImage.style.visibility = "hidden";
			})





		/*
		===========================
		POZNAMKA
		===========================
		*/

		const productGroups = document.querySelectorAll(".eshop-table__group");

		productGroups.forEach(function (group) {

			const header = group.querySelector(".group__head");
			const subsection = group.querySelector(".group__subsection");

			header.addEventListener('click', function (e) {
				//console.log('otvaram');
				e.currentTarget.classList.toggle('expanded')
				//subsection.classList.toggle('show');
				$(subsection).collapse("toggle");
			});
		});


}); ///END DOCUMENT.READY
