		var counterTimer;
		var debounceTimer = 500;
	
		$(".counter_field").on("change", function(e){
			if($(this).val() < 1 || $(this).val().match(/\D+/g)){
				$(this).val(1);
			}

			if(e.target.classList.contains("event-proceed")){
				e.target.closest("form").submit();
			}

			console.log('counter changed')
		});

		$(".counter .btn-minus").on("click tap", function(e)
		{
			
			e.preventDefault();
			
			if(e.currentTarget.classList.contains("event-proceed")){

				clearTimeout( counterTimer );
    			counterTimer = setTimeout(()=>{
					//console.log('debounced action')
					e.target.closest("form").submit();
				}, debounceTimer);

			}


			var counter_field = $(this).closest(".counter").find(".counter_field");
			var counter_field_value = counter_field.val();
			if(counter_field_value == 0){

				return
			}
			else{
				counter_field_value --;
				counter_field.val(counter_field_value);
			}


		});

		$(".counter .btn-plus").on("click tap", function(e)
		{
			e.preventDefault();

			if(e.currentTarget.classList.contains("event-proceed")){

				clearTimeout( counterTimer );
    			counterTimer = setTimeout(()=>{
					//console.log('debounced action')
					e.target.closest("form").submit();
				}, debounceTimer);

			}

			var counter_field = $(this).closest(".counter").find(".counter_field");
			var counter_field_value = counter_field.val();
			counter_field_value ++;
			counter_field.val(counter_field_value);
		});
